<template>
  <div class="main-container">
    <h1 class="main-heading">{{$t('BOSS')}}</h1>
    <div class="content-container">
      <div class="first-section">
        <div class="col">
          <div class="col-title">{{ $t('PROPERTIES') }}</div>
          <div class="form-container">
            <p>{{ $t(`BOSS_VALUES.NAME`) }}</p>
            <input v-model="currentBoss.name"/>
          </div>
          <div class="form-container">
            <p>{{ $t(`BOSS_VALUES.DESC`) }}</p>
            <textarea v-model="currentBoss.description"/>
          </div>
          <div>
            <select v-model="selectedLanguage">
              <option v-for="language in languages" v-bind:key="language.code" :value="language.code">{{ language.name }}</option>
            </select>
          </div>
          <div class="form-container">
            <p>{{ "Localized Name" }}</p>
            <input v-model="currentBoss.localizables.name[selectedLanguage]"/>
          </div>
          <div class="form-container">
            <p>{{ "Localized Description" }}</p>
            <textarea v-model="currentBoss.localizables.description[selectedLanguage]"/>
          </div>
          <div class="form-container">
            <p>{{ $t(`BOSS_VALUES.INDEX`) }}</p>
            <input v-model.number="currentBoss.index"/>
          </div>
        </div>
      </div>
      <div class="buttons-section">
        <button v-if="$route.query.id" @click="openConfirm" class="info">{{ $t('SUBMIT') }}</button>
        <button v-else @click="submit" class="info">{{ $t('SUBMIT') }}</button>
        <template v-if="$route.query.id">
          <button v-if="env === 'staging' || env === 'prod'" class="info" @click="() => exportTo('dev')">Export to Development</button>
          <button v-if="env === 'dev' || env === 'prod'" class="info" @click="() => exportTo('staging')">Export to Staging</button>
          <button v-if="env === 'dev' || env === 'staging'" class="info" @click="() => exportTo('prod')">Export to Production</button>
        </template>
      </div>
    </div>
        <ConfirmPopup :popup-open="popupOpen" :text="popupTextDisplay" @popup-close="popupOpen=false" :type="popupType" :changes="changes" @confirm="submit"/>
  </div>
</template>

<script>
import { computed, reactive, watch, ref, onBeforeMount, onMounted, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import bossSchema from "@/schemas/boss";
import languageConstants from "@/constants/languagesTemp";
import dispatchMap from "@/constants/dispatchMap";
import fieldMap from "@/constants/fieldMap";
import { io } from "socket.io-client";
import ConfirmPopup from '../../components/common/ConfirmPopup.vue'

export default {
  name: "Boss",
  components: {
    ConfirmPopup
  },
  setup() {
    const store = useStore();
    const currentBoss = reactive({ ...bossSchema })
    const route = useRoute();
    const selectedLanguage = ref('en')
    const languages = languageConstants.languages;
    const usedPages = ["bosses"];
    const socket = io(process.env.VUE_APP_BACKEND_URL);
    const popupOpen = ref(false);
    const popupText = "This page is edited by another user. Please refresh before making changes.";
    const popupTextDisplay = ref("");
    const popupType = ref("refresh");
    const changes = ref([]);
    let firstBoss = null;
    onBeforeMount(() => {
      usedPages.forEach(page => {
      dispatchMap[page].forEach(dispatchStr => store.dispatch(dispatchStr));
    })
    })

    watch(() => store.getters['bosses/getBosses'], bosses => {
      if(route.query.id && bosses.length > 0) {
        const editingBoss = JSON.parse(JSON.stringify(bosses.find(x => x.id === route.query.id)))
        Object.keys(editingBoss).forEach(key => {
          currentBoss[key] = editingBoss[key]
        })
        if (!currentBoss.localizables) {
          currentBoss.localizables = { description: {}, name: {} }
        }
        if (!currentBoss.localizables.name){
          currentBoss.localizables.name = {}
        }
        if (!currentBoss.localizables.description)
          currentBoss.localizables.description = {}
        firstBoss = JSON.parse(JSON.stringify(currentBoss));
      }
    })

    onMounted(() => store.dispatch("loader/loadingStatus", false));
    onBeforeUnmount(() => store.dispatch("loader/loadingStatus", true));
    const submit = () => {
      if(route.query.id) {
          store.dispatch('bosses/updateBoss', { id: route.query.id, data: currentBoss }).then(() => {
          socket.emit("update", {
            socketId: socket.id,
            itemId: currentBoss.id,
            page: "Bosses",
            data: currentBoss
          });
          popupOpen.value = false;
          changes.value = [];
        })
      } else {
        store.dispatch('bosses/addBoss', { data: currentBoss })
      }
    }

    const exportTo = environment => {
      store.dispatch('bosses/exportBoss', { id: route.query.id, data: currentBoss, environment })
    }

    socket.on('updated', (data) => {
      if (data.socketId != socket.id && data.itemId === currentBoss.id && data.page === "Bosses" && data.data){
        popupOpen.value = true;
        popupType.value = "refresh";
        Object.keys(data.data).forEach(field => {
          if (currentBoss[field] != data.data[field]){
            if(field != "localizables")
              changes.value.push((fieldMap["bosses"][field] + ": " + currentBoss[field] + " => " + data.data[field]));
          }

        })
        popupTextDisplay.value = popupText;
      }
    })
    const appConfig = computed(() => store.getters['auth/getAppConfig'])
    const env = appConfig.value.appEnv || 'dev'

    const openConfirm = () => {
      popupType.value = "confirm";
      popupOpen.value = true;
      Object.keys(firstBoss).forEach(field => {
          if (firstBoss[field] != currentBoss[field]){
            if(field != "localizables")
              changes.value.push((fieldMap["bosses"][field] + ": " + firstBoss[field] + " => " + currentBoss[field]));
          }
        })
    }

    return {
      currentBoss,
      submit,
      exportTo,
      env,
      selectedLanguage,
      languages,
      popupOpen,
      popupTextDisplay,
      popupType,
      changes,
      openConfirm
    }
  }
}
</script>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}

.content-container {
  width: 100%;
}
.first-section {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: start;
}

p {
  margin-bottom: 0;
  font-size: 0.875rem;
}

input, textarea, select {
  width: calc(100% - 25px);
  padding: 5px 0;
}

.col-title {
  font-weight: 600;
  font-size: 1.125rem;
}

.col-subtitle {
  margin: 15px 0;
  font-weight: 600;
}

select {
  margin: 5px 0;
}

button {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px 20px;
  margin: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  color: black;
}

.buttons-section {
  display: flex;
  padding: 30px;
  flex-direction: column;
  align-items: stretch;
}

.buttons-section button {
  font-size: 1.25rem;
}

button.error {
  background-color: rgba(255, 0, 0, 0.15);
}

button.success {
  background-color: rgba(0, 255, 0, 0.15);
}

button.info {
  background-color: rgba(0, 0, 255, 0.15);
}

</style>